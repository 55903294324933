@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full;
    @apply scroll-smooth;
  }

  body {
    @apply h-full;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

*:active, *:focus {
  outline: none;
}

.printable-content-only {
  display: none;
}

.hide-scrollbar {
  overflow-y: hidden;
}

@media print {
  .printable-content-only {
    display: block;
  }
}
